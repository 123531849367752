/* mobile */
@media (max-width: 420px) {
    #mainBlogIconMobile {
        margin-left: 6rem;
        width: 9.92rem;
    }
    #videoPlayer {
        display: none;
    }
    #videoPlayer2 {
        width: 0rem;
    }
    #videoSection {
        margin-left: 0rem;
        margin-top: 0rem;
        height: 0rem;
        width: 0rem;
        opacity: 0;
    }
    #mainMediaIconMobile {
        position: absolute;
    }
    #mainMediaIcon, #mainFashionIcon, #mainVisualIcon, #mainBlogIcon, #about {
        display: none;
    }
    #mainMediaIconMobile, #mainFashionIconMobile, #mainVisualIconMobile {
        width: 150px;
        height: 230.5px;
    }
    #pill {
        width: 36.95px;
        margin-left: 3.365rem;
    }
    #aboutMobile {
        width: 268.14px;
        margin-right: 6.07rem;
        margin-left: 4.98rem;
    }
    #homeBody {
        padding-bottom: 130px;
        background-size: cover;
    }
    #homeItems {
        padding-top: 4.478%;
        margin-top: 17%;
        margin-left: 2%;
        display: flex
    }
    #draggableItem {
        display: none;
    }
    #player {
        margin-right: 8.96rem;
    }
    #insta {
        margin-top: auto;
        padding-right: 0%;
        margin-left: 0rem;
        width: 10%;
    }
    #youtube {
        margin-top: auto;
        padding-right: 0%;
        margin-left: 2rem;
        width: 10%;
    }
}
@media (min-width: 421px) {
    #homeItems {
        margin-left: 3.55%;
    }
    #mainBlogIcon {
        width: 17.956rem;
    }
    #videoPlayer {
        display: none;
        /* width: 50.329rem; */
    }
    #videoPlayer2 {
        display: none;
    }
    #videoSection {
        margin-left: 8.455rem;
        margin-top: 19.995rem;
        height: 32.813rem;
        width: 48.938rem;
    }
    #mainMediaIcon, #mainFashionIcon, #mainVisualIcon {
        width: 272px;
    }
    #mainMediaIconMobile, #mainFashionIconMobile, #mainVisualIconMobile, #mainBlogIconMobile, #aboutMobile {
        display: none;
    }
    #pill {
        width: 67px;
        margin-left: 6.365rem;
    }
    #about {
        width: 486.219px;
        margin-right: 11rem;
        margin-left: 15rem;
    }
    #homeBody {
        margin-top: -58px;
        background-size: auto;
        background-size: cover;
    }
    #homeItems {
        padding-bottom: 3.6%;
        margin-top: 9.05%;
    }
    #draggableItem {
        display: block;
    }

    #player {
        margin-right: 16.25rem;
    }
    #insta {
        margin-top: auto;
        padding-right: 0%;
        margin-left: 10rem;
        width: 3%;
    }
    #youtube {
        margin-top: auto;
        padding-right: 0%;
        margin-left: 4rem;
        width: 3%;
    }
}
#home {
    width: fit-content;
    height: fit-content;
}
#homeBody {
    background-image: url('../assets/main/main_bg.svg');
    background-repeat: no-repeat;
    position: relative;
    overflow-x: hidden;
}

#draggableItem {
    top: 16%;
    left: 60%;
    position: fixed;
    font-size: 125%;
    width: 32%;
    text-align: center;
    cursor: move;
    z-index: 1;
}
#draggableText {
    color: #161619;
    margin: 0;
    padding-top: 3.8%;
    padding-bottom: 3.8%;
    background-color: #FFFFFF;
    border: 1px solid #43434C;
}
#draggableShadow {
    height: 13px;
    background: #43434C;
    border: 1px solid #43434C;
}
#videoSection {
    display: none;
    /* object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    -webkit-clip-path: polygon(7% 17%, 96% 8%, 96% 84%, 7% 93%);
    clip-path: polygon(7% 17%, 96% 8%, 96% 84%, 7% 93%); */
}
#mainBlogIcon {
    margin-top: auto;
    margin-bottom: 1.188rem;
    margin-left: 0rem;
    cursor: pointer;
}
#mainMediaIcon {
    margin-top: 5%;
    cursor: pointer;
    position: absolute;
}
#mainFashionIcon, #mainVisualIcon {
    margin-left: 0.75rem;
    margin-top: 5%;
    cursor: pointer;
}
#mainMediaIconMobile, #mainFashionIconMobile, #mainVisualIconMobile, #mainBlogIconMobile, #aboutMobile {
    margin-top: auto;
}
#pill {
    margin-top: auto;
    margin-bottom: 0.6%;
}
#about, #aboutMobile {
    margin-top: auto;
    padding-right: 0%;
}

