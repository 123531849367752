/* mobile */
/* @media (max-width: 420px) {
  .text-logo {
    width: 230px;
    height: auto;
  }
} */
/* desktop */
@media (min-width: 421px) {

}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  /* overflow: scroll; */
}
ul,
ol {
  list-style: none;
}
a {
  text-decoration: none
}
img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-use-select: none;
  user-select: none;
}
.flex {
  display: flex;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.margin-auto {
  margin: auto;
}
.page {
  height: 100%;
  margin: 0;
}
.logo {
  width: 31rem;
}
.text-logo {
  width: 29.5%;
  height: auto;
  animation: logoScale 1.5s alternate infinite ease-in;
}
@keyframes logoScale {
  0% {transform: scale(.8);}
  50% {transform: scale(1);}
  100% {transform: scale(.8);}
}