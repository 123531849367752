@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap');

/* @font-face {
  font-family: 'Orbitron';
  font-weight: 400, 500, 600, 700, 800, 900;
  src: url('./assets/fonts/Orbitron-VariableFont_wght.ttf') format('truetype');
} */

.regular {
  font-weight: 400;
}
.medium {
  font-weight: 500;
}
.semi-bold {
  font-weight: 600;
}

* {
  font-family: 'Orbitron', sans-serif;
}

html, body, #root, .App {
    min-height:100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
