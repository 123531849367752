/* mobile */
@media (max-width: 420px) {
    .back-btn {
        font-size: 0.9rem;
        margin-left: 5%;
        margin-top: 20%;
    }
    .serve-filter {
        font-size: 0.9rem;
        margin-right: 5%;
        margin-top: 20%;
    }
   
    .blog-post-title {
        font-size: 1.2rem;
    }
    .blog-post-subtitle {
        font-size: 0.8rem;
    }
    .blog-content-body {
        margin: auto;
        width: 90%;
    }
}


/* tablet & desktop */
@media (min-width: 421px) {
    .back-btn {
        font-size: 1.25rem;
        margin-top: 18%;
        margin-left: 6%;
    }
    .serve-filter {
        font-size: 1.25rem;
        margin-right: 6%;
        margin-top: 18%;
    }
    .blog-post-title {
        font-size: 2rem;
    }
    .blog-post-subtitle {
        font-size: 15px;
    }
    .blog-content-body {
        margin: auto;
        width: 50%;
    }
    .blog_img {
        max-width: 800px;
    }

}




.back-btn {
    margin-left: 7%;
    cursor: pointer;
    z-index: 0;
    font-weight: 600;
    position: fixed;
    display: flex;
}
.serve-filter {
    font-weight: 500;
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
}
.blog-post-body {
    display: flex;
    flex-direction: column;
    margin-bottom: 3.75rem;
}
.blog-post-logo {
    width: 13.3%;
    margin: auto;
    margin-top: 10.3%;
    margin-bottom: 4.2%;
}
.blog-post-title {
    margin: auto;
    margin-bottom: 1%;
}
.blog-post-subtitle {
    margin: auto;
    margin-bottom: 3.75%;
}


.blog_title {
font-size: 18px;
color: #333333;
}

.blog_subtitle{
font-size: 16px;
color: #333333;
}

.article{
    line-height: 30px;
    font-family: 'Noto Sans KR', sans-serif;
    word-spacing: -2px;
    color: #333333;
    font-size: 14px;
    word-break: keep-all;
}

hr {
    height: 0.5px;
    border: 0;
    background-color: #7E7E86;
}

.tip {
    color: #7E7E86;;
}
