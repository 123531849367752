/* mobile */
@media (max-width: 420px) {
    #footer {
        display: none;
    }
}
/* tablet & desktop */
@media (min-width: 422px) {
    #footer {
        display: flex;
    }
}
#footer {
    height: 50px;
    border-bottom: 1px solid #707070;
    /* border-top: 1px solid #707070; */
    background-color: white;
    /* bottom: 0;
    position: fixed; */
}
.marquee {
    height: 50px;
    overflow: hidden;
}
.marquee-content {
    flex-shrink: 0;
    list-style: none;
    min-width: 100%;
    margin-top: 12px;
    animation: marquee 30s linear infinite;
}
.footer-pill-1 {
    animation: rotation1 6s infinite linear;
}
.footer-pill-2 {
    animation: rotation2 6s infinite linear;
}
.footer-text {
    /* font-family: 'Sans-serif'; */
    font-size: 1.25rem;
    width: fit-content;
}
@keyframes marquee {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translatex(-246.683rem);
    }
}
@keyframes rotation1 {
    0% {
        transform: rotate(0deg)
    }
    100% {
        transform: rotate(359deg)
    }
}
@keyframes rotation2 {
    0% {
        transform: rotate(90deg)
    }
    100% {
        transform: rotate(449deg)
    }
}