/* mobile */
@media (max-width: 820px) {
    #startLoadingImg {
        width: 250px;
        height: 250px;
    }
    #startLoadingMessage {
        font-size: 1.875rem;
    }
}
/* desktop */
@media (min-width: 821px) {
    #startLoadingImg {
        width: 500px;
        height: 500px;
    }
    #startLoadingMessage {
        font-size: 3.75rem;
    }
}
#startLoadingImg {
    margin-bottom: 0;
}

#startLoadingMessage {
    margin-top: 0;
    color: #161619;
}