/* mobile */
@media (max-width: 820px) {
    #secondFooter {
        display: none;
    }
}
/* desktop */
@media (min-width: 821px) {
    #secondFooter {
        display: flex;
    }
}
#firstSection {
    border-top: 1px solid #43434B;
    border-bottom: 1px solid #43434B;
    padding-top: 60px;
    padding-bottom: 60px;
}
#secondSectionText {
    font-family: 'Sans-serif';
    text-align: right;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    padding-right: 1.25rem;
}
.footer-component {
    margin: auto;
    position: relative;
    cursor: pointer;
}
.footer-icon {
    height: 48px;
    vertical-align: middle;
}
.second-footer-text {
    font-size: 1.725rem;
    font-weight: 600;
    text-align: center;
    position: absolute;
    top: 50%;
	left: 50%;
    transform: translate( -50%, -50% );
}