@media (max-width: 420px) {
    #navHeader {
        display: none;
    }
}
@media (min-width: 421px) {
    #navHeader {
        display: flex;
    }
}
#navHeader {
    height: 3.125rem;
    width: 100%;
    border-bottom: 1px solid #707070;
    border-top: 1px solid #707070;
    background-color: white;
    justify-content: space-between;
    position: fixed;
    z-index: 3;
}
#headerLogo {
    height: 1.25rem;
    margin: auto;
    /* margin-top: 15px; */
    margin-left: 6%;
    cursor: pointer;
}
#nav {
    margin-right: 6%;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 600;
}
#navAbout {
    cursor: pointer;
}
#navBlog {
    margin-left: 3.13rem;
    cursor: pointer;
}
