/* mobile */
@media (max-width: 420px) {
    #card {
        width: 300px;
        height: 200px;
        margin: auto;
        margin-bottom: 35px;
    }
    .image {
        width: 300px;
        height: 200px;
    }
    .overlay {
        height: 2.094rem;
    }
    .title {
        height: 2.094rem;
        font-size: 0.625rem;
    }
}
/* tablet */
@media (min-width: 421px) and (max-width: 1180px) {
    #card {
        width: 600px;
        height: 400px;
        margin: auto;
        margin-bottom: 50px;
    }
    .image {
        width: 600px;
        height: 400px;
    }
    .overlay {
        height: 4.3rem;
    }
    .title {
        height: 4.188rem;
        font-size: 1.25rem;
    }
}
/* desktop */
@media (min-width: 1181px){
    #card {
        width: 600px;
        height: 400px;
    }
    .image {
        width: 600px;
        height: 400px;
    }
    .overlay {
        overflow: hidden;
        transition: .5s ease;
        height: 0;
    }
    #card:hover .overlay {
        height: 4.3rem;
    }
    .title {
        height: 4.188rem;
        font-size: 1.25rem;
    }
}
#card {
    background-color: #DBDBDB;
    cursor: pointer;
    position: relative;
}
.overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
}
.title {
    display: flex;
    border: 1px solid #707070;
    background-color: #FFFFFF;
    font-family: 'Sans-serif';
    margin: auto;
}
.title-text {
    margin: auto;
}
.image {
    object-fit: cover;
}