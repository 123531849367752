@media (max-width: 420px) {
    .serve-back-btn {
        font-size: 0.9rem;
        margin-left: 5%;
        margin-top: 20%;
    }
    .serve-filter {
        font-size: 0.9rem;
        margin-right: 5%;
        margin-top: 20%;
    }
    .serve-post-title {
        font-size: 1rem;
    }
    .serve-post-subtitle {
        font-size: 0.7rem;
    }
    .serve-content-body {
        margin: auto;
        width: 100%;
    }
}
@media (min-width: 421px) {

    .serve-back-btn {
        font-size: 1.25rem;
        margin-top: 18%;
        margin-left: 6%;
    }
    .serve-filter {
        font-size: 1.25rem;
        margin-right: 6%;
        margin-top: 18%;
    }
    .serve-post-title {
        font-size: 2rem;
    }
    .serve-post-subtitle {
        font-size: 1.25rem;
    }
    .serve-content-body {
        margin: auto;
        width: 75%;
    }
}
#servePostLogoFashion {
    width: 10.9%;
    margin: auto;
    margin-top: 14.5%;
    margin-bottom: 6%;
}
.serve-post-body {
    background-image: url('../../assets/serve_top_bg.png');
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    margin-bottom: 5.5%;
}
.serve-post-title {
    margin: auto;
    margin-bottom: 1.5%;
}
.serve-post-subtitle {
    margin: auto;
    margin-bottom: 5.5%;
}
/* .serve-post-btn-n-filter {
    display: flex;
    justify-content: space-between;
    margin-top: 18%;
    margin-left: 9%;
    margin-right: 9%;
} */
.serve-back-btn {
    font-weight: 600;
    cursor: pointer;
    position: fixed;
    display: flex;
}
.serve-filter {
    font-weight: 500;
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
}
.content-img {
    margin-top: 0px;
    margin-bottom: 0px;
    width: 100%;
}
.content-video {
    position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%;
        margin: 0px 0px;
        background-color: black;
}
.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
.responsive-iframe { 
    position: absolute; 
    top: 0; left: 0; width: 100%; height: 100%; 
}
.black-back {
    background-color: black;
}

.cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 100px;
    height: 345px;
    overflow-x: auto;
  }
  
  .carousel-arrow {
    position: absolute;
    top: 50%; /* 중앙 정렬 */
    transform: translateY(-50%);
  }