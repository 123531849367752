/* mobile */
@media (max-width: 1180px) {
    .matter {
        display: none;
    }
    .serveText {
        font-size: 16px;
    }
    .filterItem {
        margin-right: 1.5rem;
    }
    #mediaFilterAll {
        padding-left: 40px;
    }
    #visualIcon, #fashionIcon, #mediaIcon {
        height: 55px;
        margin-top: 110px;
        margin-bottom: 8px;
    }
    .serveText {
        margin-bottom: 60px;
    }
    .filter-hr {
        display: block;
    }
    .filter {
        margin-bottom: 10px;
    }
    .cards-arrow {
        display: none;
    }
}
/* mobile 2 */
@media (max-width: 420px) {
    .serveBody {
        margin-bottom: 350px;
    }
    #visualIcon, #fashionIcon, #mediaIcon {
        height: 55px;
        margin-top: 110px;
        margin-bottom: 8px;
    }
    #mediaFilter {
        width: 100%;
        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow-x: scroll;
    }
    #mediaFilter::-webkit-scrollbar {
        display: none;
    }
    .serveText {
        margin-bottom: 60px;
    }
    .filter-hr {
        display: block;
    }
    .filter {
        margin-bottom: 10px;
    }
    .cards-empty-space-left {
        width: 90px;
    }
    .cards-arrow {
        width: 20px;
        margin-left: 20px;
        margin-top: 100px;
    }
    .filterItem {
        font-size: 16px;
    }
}
/* tablet & desktop */
@media (min-width: 421px) {
    .serveBody {
        background-image: url('../assets/serve_top_bg.png');
        background-repeat: no-repeat;
        background-size: contain;
        margin-bottom: 10rem;
    }
    .filter-hr {
        display: none;
    }
    .filter {
        margin-bottom: 7.401rem;
    }
    .cards-empty-space-left {
        height: 300px;
        width: 300px;
    }
    .cards-arrow {
        margin-left: 100px;
        margin-top: 100px;
    }
}
/* desktop */
@media (min-width: 1181px) {
    .matter {
        display: block;
    }
    .serveText {
        font-size: 32px;
        margin-bottom: 2.6%;
    }
    .filterItem {
        margin-right: 2.938rem;
    }
    .serveBody {
        background-image: url('../assets/serve_top_bg.png');
        background-repeat: no-repeat;
        background-size: contain;
        margin-bottom: 15.248rem;
    }
    #visualIcon {
        width: 12.75%;
        margin-top: 11.25%;
        margin-bottom: 1.2%;
    }
    #fashionIcon {
        width: 16%;
        margin-top: 11%;
        margin-bottom: 1.2%;
    }
    #mediaIcon {
        width: 8.6%;
        margin-top: 11%;
        margin-bottom: 1.2%;
    }
    .filter {
        margin-bottom: 7.401rem;
    }
}
.serveBody {
    position: relative;
}
#fashionIcon, #visualIcon, #mediaIcon {
    margin-left: auto;
    margin-right: auto;
}
.serveText {
    font-weight: 600;
    margin-left: auto;
    margin-right: auto;
}
.filter {
    z-index: 2;
    margin-right: auto;
    margin-left: auto;
}
.filterItem {
    cursor: pointer;
    font-weight: 500;
}
.matter {
    position: absolute;
}
.filter-hr {
    border: 0;
    width: 100%;
    height: 0.5px;
    background: #707070;
    margin-bottom: 60px;
}
.cards-arrow {
    position: absolute;
    z-index: 1;
}

.cards-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-x: scroll;
    position: relative;
    z-index: 1;
}

.cards-wrapper {
    overflow: visible;
}

.cards-container, .cards-wrapper {
    position: relative;
}

.cards-arrow-r {
    position: absolute;
    right: 10px; /* 필요 시 조정 */
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 2;
}


.carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
